import axios from 'axios';

const sendSMSNotifications = (mobile, waybill) => {
    const url = 'https://api-pudo.co.za/api/v1/precapture-send-message';
    const token = '2440090|Zd9fFs9QSoBXCI8oVuGvBbrVW90jEAGLCvG4Cdq1cdb3e70d';
    const body = {
        waybill: waybill,
        mobile: mobile
    };

    axios.post(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            console.log('Success:', response.data);
        })
        .catch(error => {
            console.log('Error:', error.response ? error.response.data : error.message);
        });
}

export default sendSMSNotifications